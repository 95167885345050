import { Route, Switch, useRouteMatch } from 'react-router'

import Site from './Site'

export default function SiteRoutes({
  plateID,
  wellPos,
}: { plateID: string; wellPos: string }) {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        // TODO(SWE-1337): Redirect up to well view.
        render={() => <div />}
      />

      <Route
        exact
        path={`${match.path}/:index`}
        render={({
          match: {
            params: { index },
          },
        }) => <Site plateID={plateID} wellPos={wellPos} index={index} />}
      />
    </Switch>
  )
}
