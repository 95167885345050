import cx from 'classnames'

import cs from './lateral_nav_compass.scss'

export enum LateralNavDirection {
  UP = 'UP',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export default function LateralNavCompass({
  onNavigate,
  upLabel,
  downLabel,
  leftLabel,
  rightLabel,
}: {
  onNavigate: (direction: LateralNavDirection) => void
  upLabel: string
  downLabel: string
  leftLabel: string
  rightLabel: string
}) {
  return (
    <div className={cs.compass}>
      <Control
        direction={LateralNavDirection.UP}
        label={upLabel}
        onNavigate={onNavigate}
      />

      <Control
        direction={LateralNavDirection.DOWN}
        label={downLabel}
        onNavigate={onNavigate}
      />

      <Control
        direction={LateralNavDirection.LEFT}
        label={leftLabel}
        onNavigate={onNavigate}
      />

      <Control
        direction={LateralNavDirection.RIGHT}
        label={rightLabel}
        onNavigate={onNavigate}
      />
    </div>
  )
}

const DIRECTION_CLASS_NAME = {
  [LateralNavDirection.UP]: cs.north,
  [LateralNavDirection.DOWN]: cs.south,
  [LateralNavDirection.LEFT]: cs.west,
  [LateralNavDirection.RIGHT]: cs.east,
}

function Control({
  direction,
  label,
  onNavigate,
}: {
  direction: LateralNavDirection
  label: string
  onNavigate: (direction: LateralNavDirection) => void
}) {
  // There are two components in a control:
  //
  //   - The "indicator" contains the arrow and label, which provide visual
  //     feedback when the control is hovered. The arrow occupies the
  //     indicator's grid area, while the label *overflows* the grid area and is
  //     always outside the compass bounding box.
  //
  //     This allows the caller to position the compass relative to the arrows'
  //     boundaries, keeping the arrows in the same position as labels appear,
  //     disappear, or change.
  //
  //   - The "interaction target" is an invisible element that creates a
  //     diamond-shaped interactive region around the indicator, allowing users
  //     to hover and click this region without needing to precisely position
  //     the mouse over the indicator.
  //
  // The indicator and interaction target are siblings, not parent-child. This
  // is because the interaction target's transform origin (its reference
  // position before translation and rotation are applied) needs to be the same
  // grid area as the indicator, but the indicator shouldn't be transformed.
  return (
    <>
      <div className={cx(cs.indicator, DIRECTION_CLASS_NAME[direction])}>
        {direction === LateralNavDirection.UP ||
        direction === LateralNavDirection.LEFT ? (
          <>
            <span className={cs.label}>{label}</span>
            <Arrow direction={direction} />
          </>
        ) : (
          <>
            <Arrow direction={direction} />
            <span className={cs.label}>{label}</span>
          </>
        )}
      </div>

      {/* biome-ignore lint/a11y/useAnchorContent: We use aria-label here instead. */}
      <a
        className={cx(cs.interactionTarget, DIRECTION_CLASS_NAME[direction])}
        onClick={() => {
          onNavigate(direction)
        }}
        aria-label={label}
      ></a>
    </>
  )
}

function Arrow({ direction }: { direction: LateralNavDirection }) {
  if (direction === LateralNavDirection.UP) {
    return (
      <svg
        className={cs.arrow}
        width='16'
        height='13'
        viewBox='0 0 16 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M6.26795 1C7.03775 -0.333332 8.96225 -0.333334 9.73205 0.999999L14.9282 10C15.698 11.3333 14.7358 13 13.1962 13H2.80385C1.26425 13 0.301996 11.3333 1.0718 10L6.26795 1Z' />
      </svg>
    )
  }

  if (direction === LateralNavDirection.DOWN) {
    return (
      <svg
        className={cs.arrow}
        width='16'
        height='13'
        viewBox='0 0 16 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M9.73205 12C8.96225 13.3333 7.03775 13.3333 6.26795 12L1.0718 3C0.301996 1.66667 1.26425 0 2.80385 0L13.1962 0C14.7358 0 15.698 1.66667 14.9282 3L9.73205 12Z' />
      </svg>
    )
  }

  if (direction === LateralNavDirection.LEFT) {
    return (
      <svg
        className={cs.arrow}
        width='13'
        height='16'
        viewBox='0 0 13 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M1 9.73205C-0.333332 8.96225 -0.333334 7.03775 0.999999 6.26795L10 1.0718C11.3333 0.301996 13 1.26424 13 2.80385L13 13.1962C13 14.7358 11.3333 15.698 10 14.9282L1 9.73205Z' />
      </svg>
    )
  }

  return (
    <svg
      className={cs.arrow}
      width='13'
      height='16'
      viewBox='0 0 13 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M12 6.26795C13.3333 7.03775 13.3333 8.96225 12 9.73205L3 14.9282C1.66667 15.698 -6.34004e-07 14.7358 -5.75679e-07 13.1962L-1.81985e-07 2.80385C-1.2366e-07 1.26425 1.66667 0.301995 3 1.0718L12 6.26795Z' />
    </svg>
  )
}
