import cx from 'classnames'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'
import {
  PlateHeroFragmentFragment,
  WellCultureStatusGraphQl,
} from '~/__generated__/graphql'
import {
  MONTAGE_LAYOUT_ROWS_COLS,
  PLACEHOLDER_MONTAGE_LAYOUT_FOR_PLATE_FORMAT,
} from '~/utils/montage'

import CloseIcon from './icons/CloseIcon'
import DeleteIcon from './icons/DeleteIcon'
import ViewHiddenIcon from './icons/ViewHiddenIcon'
import cs from './plate_hero.scss'

const fragment = gql(`
  fragment PlateHeroFragment on CulturePlateGraphQL {
    id
    plateDimensions {
      rows
      columns
    }
    wellCultures {
      id
      well
      status
    }
    plateObservationHistory {
      timestamp
      sprite {
        imageUrl(size: SIZE_200X200)
        montageLayout
        wellSequence
      }
    }
  }
`)

type Culture = PlateHeroFragmentFragment['wellCultures'][number]
type PlateObservation = PlateHeroFragmentFragment['plateObservationHistory'][number]

export default function PlateHero(props: {
  plate: FragmentType<typeof fragment>
}) {
  const plate = unmaskFragment(fragment, props.plate)
  const { rows: plateRows, columns: plateColumns } = plate.plateDimensions
  const numWellsInFormat = plateRows * plateColumns // Not necessarily equal to the number of cultures or images.

  const cultureByWell: { [well: string]: Culture | undefined } = useMemo(() => {
    return Object.fromEntries(
      plate.wellCultures.map(culture => [culture.well, culture]),
    )
  }, [plate.wellCultures])

  const observation = plate.plateObservationHistory[
    plate.plateObservationHistory.length - 1
  ] as PlateObservation | undefined

  // ["A1", "A6", "B4", ...] -> {A1: 0, A6: 1, B4: 2, ...}
  const spriteWellIndices = Object.fromEntries(
    observation?.sprite.wellSequence.map((well, i) => [well, i]) ?? [],
  )

  return (
    <div
      className={cx(cs.container, { [cs.format96]: numWellsInFormat === 96 })}
      style={{
        ['--plate-rows' as string]: plateRows,
        ['--plate-columns' as string]: plateColumns,

        ['--sprite-url-2x' as string]: observation
          ? `url("${observation.sprite.imageUrl}")`
          : null,
      }}
    >
      {new Array(numWellsInFormat).fill(null).map((_, i) => {
        const row = Math.floor(i / plateColumns),
          col = i % plateColumns
        const rowLetter = String.fromCharCode('A'.charCodeAt(0) + row)
        const well = `${rowLetter}${col + 1}`
        const culture = cultureByWell[well]
        // Index of this well in the current sprite. The same well may have
        // different indexes in different sprites.
        const spriteWellIndex = spriteWellIndices[well]

        return (
          <div key={well} className={cs.well}>
            {spriteWellIndex != null ? (
              <ImageryLayer
                observation={observation}
                spriteWellIndex={spriteWellIndex}
                numWellsInFormat={numWellsInFormat}
              />
            ) : (
              <div className={cx(cs.layer, cs.notImaged)}>
                <NotImagedIndicator culture={culture} />
              </div>
            )}

            {culture ? (
              <Link
                className={cx(cs.layer, cs.linkToCulture)}
                to={`/plate/${plate.id}/well/${well}`}
                draggable={false}
              />
            ) : null}
          </div>
        )
      })}
    </div>
  )
}

function ImageryLayer({
  observation,
  spriteWellIndex,
  numWellsInFormat,
}: {
  observation?: PlateObservation
  spriteWellIndex: number
  numWellsInFormat: number
}) {
  const montageLayout =
    observation?.sprite.montageLayout ??
    PLACEHOLDER_MONTAGE_LAYOUT_FOR_PLATE_FORMAT[numWellsInFormat]
  const [montageRows, montageColumns] = MONTAGE_LAYOUT_ROWS_COLS[montageLayout]
  const numSitesPerMontage = montageRows * montageColumns

  return (
    <div
      className={cx(cs.layer, cs.imagery)}
      style={{
        ['--montage-rows' as string]: montageRows,
        ['--montage-columns' as string]: montageColumns,
        ['--num-sites-per-montage' as string]: numSitesPerMontage,

        // --sprite-num-wells is the number of wells captured in the sprite.
        // This is not always the number of wells or cultures on the plate, and
        // it can vary from one imaging run to another.
        ['--sprite-num-wells' as string]: observation?.sprite.wellSequence.length,
        ['--sprite-well-index' as string]: spriteWellIndex,
      }}
    >
      {new Array(numSitesPerMontage).fill(null).map((_, site) => {
        return (
          <div
            key={site}
            className={cs.site}
            style={{ ['--site-index' as string]: site }}
          />
        )
      })}
    </div>
  )
}

function NotImagedIndicator({ culture }: { culture?: Culture }) {
  if (culture?.status === WellCultureStatusGraphQl.Terminated) {
    return (
      <>
        <DeleteIcon className={cs.icon} />
        <div className={cs.iconTooltip}>Terminated</div>
      </>
    )
  }

  if (culture?.status === WellCultureStatusGraphQl.Consumed) {
    return (
      <>
        <DeleteIcon className={cs.icon} />
        <div className={cs.iconTooltip}>Consumed</div>
      </>
    )
  }

  if (culture != null) {
    return (
      <>
        <ViewHiddenIcon className={cs.icon} />
        <div className={cs.iconTooltip}>Not Imaged</div>
      </>
    )
  }

  return (
    <>
      <CloseIcon className={cs.icon} />
      <div className={cs.iconTooltip}>Empty</div>
    </>
  )
}

const loadingPlaceholderFragment = gql(`
  fragment PlateHeroLoadingPlaceholderFragment on CulturePlateGraphQL {
    plateDimensions {
      rows
      columns
    }
  }
`)

/**
 * A very basic loading skeleton for situations where most plate data isn't in
 * the cache yet.
 *
 * For example, if the user started their session on the well page, we may not
 * have all the plate data, but we know the dimensions, so we can render a large
 * portion of the visual layout much faster using this placeholder.
 */
export function PlateHeroLoadingPlaceholder(props: {
  cached: FragmentType<typeof loadingPlaceholderFragment>
}) {
  const cached = unmaskFragment(loadingPlaceholderFragment, props.cached)
  const { rows: plateRows, columns: plateColumns } = cached.plateDimensions
  const numWellsInFormat = plateRows * plateColumns // Not necessarily equal to the number of cultures or images.

  return (
    <div
      className={cx(cs.container, cs.loadingPlaceholder, {
        [cs.format96]: numWellsInFormat === 96,
      })}
      style={{
        ['--plate-rows' as string]: plateRows,
        ['--plate-columns' as string]: plateColumns,
      }}
    >
      {new Array(numWellsInFormat).fill(null).map((_, i) => {
        return (
          <div key={i} className={cs.well}>
            <div className={cx(cs.layer, cs.notImaged)}></div>
          </div>
        )
      })}
    </div>
  )
}
