import dayjs from 'dayjs'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'
import MetadataSidebar, { MetadataItem } from '~/components/MetadataSidebar'

import cs from './well_metadata_sidebar.scss'

const fragment = gql(`
  fragment WellMetadataSidebarFragment on WellCultureGraphQL {
    createdAt
    parentCulture {
      id
    }
  }
`)

export default function WellMetadataSidebar(props: {
  culture: FragmentType<typeof fragment>
}) {
  const culture = unmaskFragment(fragment, props.culture)

  const createdAt = dayjs(culture.createdAt)
  const createdThisYear = createdAt.year() === dayjs().year()

  return (
    <MetadataSidebar>
      <MetadataItem label={culture.parentCulture == null ? 'Checked In' : 'Seeded'}>
        {createdAt.format(createdThisYear ? 'MMM D' : 'MMM D, YYYY')}&ensp;
        {createdAt.format('h:mm A')}&ensp;
        <span className={cs.secondary}>({createdAt.fromNow()})</span>
      </MetadataItem>
    </MetadataSidebar>
  )
}
