import { Redirect, Route, Switch, useRouteMatch } from 'react-router'

import WellPage from './WellPage'
import SiteRoutes from './site/SiteRoutes'

export default function WellRoutes({ plateID }: { plateID: string }) {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={({ match: { url } }) => (
          <Redirect to={url.split('/').slice(0, -1).join('/')} />
        )}
      />

      <Route
        exact
        path={`${match.path}/:pos`}
        render={({
          match: {
            params: { pos },
          },
        }) => <WellPage plateID={plateID} pos={pos} />}
      />

      <Route
        path={`${match.path}/:pos/site`}
        render={({
          match: {
            params: { pos },
          },
        }) => <SiteRoutes plateID={plateID} wellPos={pos} />}
      />
    </Switch>
  )
}
