import cx from 'classnames'
import { get } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import workcellAPI from '~/api/desktop/workcell'
import Input from '~/components/Input'
import Toaster from '~/components/Toaster'

import cs from './process_item.scss'

const ProcessItem = ({ className, processItem, live }) => {
  const [editing, setEditing] = useState(false)
  const [processItemName, setProcessItemName] = useState(
    get(['processItem', 'uuid'], processItem) || '',
  )
  const [processItemNameEdit, setProcessItemNameEdit] = useState(
    get(['processItem', 'uuid'], processItem) || '',
  )

  const currentValue = get(['processItem', 'uuid'], processItem) || ''

  useEffect(() => {
    setProcessItemName(currentValue)
    setProcessItemNameEdit(currentValue)
  }, [currentValue])

  useEffect(() => {
    setProcessItemName(currentValue)
    setProcessItemNameEdit(currentValue)
    setEditing(false)
  }, [live])

  const handleLoad = async () => {
    const oldValue = processItemName
    setProcessItemName(processItemNameEdit)
    setEditing(false)

    let success = true
    try {
      const response = await workcellAPI.loadTransferStation(
        processItem.instrumentName,
        processItem.transferStationName,
        processItemNameEdit,
      )
      success = response.success
    } catch (_error) {
      success = false
    }

    if (success) {
      Toaster.show({ message: 'Loaded process item.', intent: 'success' })
    } else {
      Toaster.show({
        message: 'Failed to load process item.',
        intent: 'danger',
      })
      setProcessItemName(oldValue)
      setProcessItemNameEdit(oldValue)
    }
  }

  const handleUnload = async () => {
    const oldValue = processItemName
    setProcessItemName('')
    setProcessItemNameEdit('')

    let success = true
    try {
      const response = await workcellAPI.unloadTransferStation(
        processItem.instrumentName,
        processItem.transferStationName,
        oldValue,
      )
      success = response.success
    } catch (_error) {
      success = false
    }

    if (success) {
      Toaster.show({ message: 'Unloaded process item.', intent: 'success' })
    } else {
      Toaster.show({
        message: 'Failed to unload process item.',
        intent: 'danger',
      })
      setProcessItemName(oldValue)
      setProcessItemNameEdit(oldValue)
    }
  }

  const handleCancel = () => {
    setEditing(false)
    setProcessItemNameEdit(get(['processItem', 'uuid'], processItem) || '')
  }

  if (processItemName) {
    return (
      <div className={cx(className, cs.processItem)}>
        <div className={cs.itemName}>{processItemName}</div>
        {!live && (
          <div className={cs.unloadAction} onClick={handleUnload}>
            Unload Item
          </div>
        )}
      </div>
    )
  }

  if (editing && !live) {
    return (
      <div className={cx(className, cs.processItem)}>
        <Input
          placeholder='Enter process item...'
          inputClassName={cs.processItemInput}
          value={processItemNameEdit}
          onChange={setProcessItemNameEdit}
        />
        <div className={cs.action}>
          <span className={cs.link} onClick={handleLoad}>
            Submit
          </span>
          <span className={cs.link} onClick={handleCancel}>
            Cancel
          </span>
        </div>
      </div>
    )
  }

  return (
    <div
      className={cx(className, cs.processItem, cs.link)}
      onClick={() => setEditing(true)}
    >
      <div className={cs.empty}>Empty</div>
      {!live && <div className={cs.loadAction}>Load Item</div>}
    </div>
  )
}

ProcessItem.propTypes = {
  className: PropTypes.string,
  processItem: PropTypes.shape({
    transferStationName: PropTypes.string,
    processItem: PropTypes.shape({
      uuid: PropTypes.string,
    }),
    instrumentName: PropTypes.string,
  }),
  live: PropTypes.bool,
}

export default ProcessItem
