import { Drawer } from '@blueprintjs/core'
import cx from 'classnames'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import Logo from '~/components/Logo'
import BuildingIcon from '~/components/icons/BuildingIcon'
import JankyDeviceIcon from '~/components/icons/JankyDeviceIcon'
import MenuIcon from '~/components/icons/MenuIcon'
import ZoomInIcon from '~/components/icons/ZoomInIcon'
import UserMenu from '~/core/UserMenu'

import CellIcon from '~/components/icons/CellIcon'
import CultureIcon from '~/components/icons/CultureIcon'
import HistoryIcon from '~/components/icons/HistoryIcon'
import LeftNavDebugInfo from './LeftNavDebugInfo'
import { MultiFrontendContext } from './MultiFrontendContext'
import cs from './left_nav.scss'

function LeftNav({ frontendContext }: { frontendContext: MultiFrontendContext }) {
  const [navOpen, setNavOpen] = useState(false)

  const isPageEnabledForOrg = page => {
    const enabledPages = frontendContext.orgMetadata.enabledPages
    if (!enabledPages) return false
    return enabledPages === 'all' || enabledPages.includes(page)
  }

  const renderLink = (label, link, icon) => (
    <NavLink
      exact
      activeClassName={cs.active}
      to={link}
      className={cs.navLinkContainer}
      onClick={() => setNavOpen(false)}
    >
      <div className={cs.iconContainer}>{icon}</div>
      <div className={cs.label}>{label}</div>
    </NavLink>
  )
  const renderUrlLink = (label, link, icon) => (
    <a href={link} className={cs.navLinkContainer}>
      <div className={cs.iconContainer}>{icon}</div>
      <div className={cs.label}>{label}</div>
    </a>
  )

  const renderLeftNav = () => {
    return (
      <>
        <NavLink to='/' className={cs.logoContainer}>
          <Logo className={cs.logo} />
        </NavLink>

        {isPageEnabledForOrg('monitor') &&
          frontendContext.deploymentMode !== 'automation' &&
          renderLink(
            'Culture Monitor',
            '/monitor',
            <CultureIcon className={cs.navLinkIconFill} />,
          )}
        {isPageEnabledForOrg('monitor') &&
          frontendContext.deploymentMode === 'automation' &&
          frontendContext.monitorEnabled &&
          renderUrlLink(
            'Culture Monitor',
            `${frontendContext.cloudFrontend}/monitor`,
            <CultureIcon className={cs.navLinkIconFill} />,
          )}
        {isPageEnabledForOrg('command_center') &&
          renderLink(
            'Live Cultures',
            '/command-center',
            <CultureIcon className={cs.navLinkIconFill} />,
          )}
        {isPageEnabledForOrg('command_center') &&
          renderLink(
            'Past Cultures',
            '/command-center/past-cultures',
            <HistoryIcon className={cs.navLinkIconFill} />,
          )}
        {isPageEnabledForOrg('cell_line_engineering') &&
          renderLink(
            'Cell Line Engineering',
            '/cell-line-engineering',
            <CellIcon className={cs.navLinkIconFill} />,
          )}
        {isPageEnabledForOrg('organoid_demo') &&
          renderLink(
            'Live Organoids',
            '/organoids',
            <ZoomInIcon className={cs.navLinkIconFill} />,
          )}
        {isPageEnabledForOrg('workcell') &&
          frontendContext.deploymentMode !== 'cloud' &&
          renderLink(
            'Workcell',
            '/workcell',
            <BuildingIcon className={cs.navLinkIconFill} />,
          )}
        {isPageEnabledForOrg('workcell') &&
          frontendContext.deploymentMode === 'cloud' &&
          frontendContext.orgMetadata.automationFrontend &&
          renderUrlLink(
            'Workcell',
            `${frontendContext.orgMetadata.automationFrontend}/workcell`,
            <BuildingIcon className={cs.navLinkIconFill} />,
          )}
        {(isPageEnabledForOrg('instruments') || isPageEnabledForOrg('workcell')) &&
          frontendContext.deploymentMode !== 'cloud' &&
          renderLink(
            'Instruments',
            '/local-instruments',
            <JankyDeviceIcon className={cs.navLinkIconFill} />,
          )}
        {(isPageEnabledForOrg('instruments') || isPageEnabledForOrg('workcell')) &&
          frontendContext.deploymentMode === 'cloud' &&
          frontendContext.orgMetadata.automationFrontend &&
          renderUrlLink(
            'Instruments',
            `${frontendContext.orgMetadata.automationFrontend}/local-instruments`,
            <JankyDeviceIcon className={cs.navLinkIconFill} />,
          )}

        <div className={cs.fill} />

        <LeftNavDebugInfo />

        <UserMenu frontendContext={frontendContext} />
      </>
    )
  }

  return (
    <div className={cs.leftNavWrapper}>
      <div className={cx(cs.leftNav, cs.desktop)}>{renderLeftNav()}</div>
      <div className={cs.mobile}>
        <div className={cs.mobileNav}>
          <MenuIcon className={cs.icon} onClick={() => setNavOpen(true)} />
          <NavLink to='/' className={cs.logoContainer}>
            <Logo className={cs.logo} />
          </NavLink>
        </div>
        <Drawer
          isOpen={navOpen}
          onClose={() => setNavOpen(false)}
          position='left'
          size={200}
        >
          <div className={cs.leftNav}>{renderLeftNav()}</div>
        </Drawer>
      </div>
    </div>
  )
}

export default LeftNav
