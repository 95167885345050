import formatRelative from 'date-fns/formatRelative'
import { enUS } from 'date-fns/locale'
import dayjs from 'dayjs'

export const renderDatetime = date => {
  return dayjs(date).format('h:mm A, MMM D')
}

export const renderTime = date => {
  return dayjs(date).format('h:mm A')
}

const z = (num, places) => String(num).padStart(places, '0')

export const displayDateDiff = (date, dateTwo) => {
  // get total seconds between the times
  let delta = Math.floor(Math.abs(dateTwo - date) / 1000)

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60

  // what's left is seconds
  const seconds = delta % 60 // in theory the modulus is not required

  if (days > 0) {
    return `${days}:${z(hours, 2)}:${z(minutes, 2)}:${z(seconds, 2)}`
  }
  return `${hours}:${z(minutes, 2)}:${z(seconds, 2)}`
}
const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'MM/dd/yyyy',
}

// This could be a constant somewhere
const defaultLocale = enUS

const locale = {
  ...defaultLocale,
  formatRelative: token => formatRelativeLocale[token],
}

// Returns values like "Next Friday", "Today", "Tomorrow"
// To render values like "one hour ago", use dayjs().fromNow()
export const renderRelativeDate = (date): string => {
  return formatRelative(date, new Date(), { locale })
}

export const renderDateTimeOnly = (date): string => {
  return dayjs(date).format('h:mm A')
}

export const dateToDisplayString = (date): string => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const roundDatetimeToMinute = (date): Date => {
  return dayjs(date).startOf('minute').toDate()
}

export const datetimeToUTCIsoStringTruncateSeconds = (date): string => {
  return dayjs(date).startOf('minute').toISOString()
}

export const datetimeToUTCIsoString = (date): string => {
  return dayjs(date).toISOString()
}

export const datetimeToDisplayString = (date): string => {
  return dayjs(date).format('YYYY-MM-DD, hh:mm A')
}
