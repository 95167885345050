import dayjs from 'dayjs'
import { createContext, useContext, useReducer } from 'react'

/**
 * This is demo data from the original dynamic workflows prototype. We can
 * remove this file after integrating the plate and well views with GraphQL.
 */

interface DemoState {
  // Data
  plates: Plate[]

  // UI
  decisionsOpen: boolean
}

interface Plate {
  id: string
  name: string
  wells: Well[]
}

export interface Well {
  index: number
  pos: string
  timepoints: Timepoint[]
  sites: Site[]
}

export interface Site {
  index: number
  timepoints: Timepoint[]
}

export interface Timepoint {
  /** The timestamp at which the timepoint was recorded. */
  actualTimestamp: Date
  /** The timestamp as adjusted for grouping with other timepoints, for example during the same imaging routine. */
  bucketTimestamp: Date
  /** The kind of action that the timestamp represents. */
  kind: 'imaged' | 'seeded'
  /** URL of the image if available, otherwise undefined. */
  image?: string
  /** Measurements captured during this timepoint. */
  measurements?: { confluence?: number }
}

const START_TIME = dayjs('2024-08-22T09:57:21-0700')

const initialState: DemoState = {
  plates: [
    { id: 'P001', name: 'ABC_DE001-FG12-002_06.01.24', wells: initializeWells(8, 12) },
    { id: 'P002', name: 'ABC_DE001-AG12-002_06.02.24', wells: initializeWells(8, 12) },
    { id: 'P003', name: 'ABC_DE001-XG12-002_06.03.24', wells: initializeWells(8, 12) },
  ],

  decisionsOpen: true,
}

type DemoAction = 'OPEN_DECISIONS' | 'CLOSE_DECISIONS'

function demoReducer(state: DemoState, action: DemoAction): DemoState {
  switch (action) {
    case 'OPEN_DECISIONS':
      return { ...state, decisionsOpen: true }

    case 'CLOSE_DECISIONS':
      return { ...state, decisionsOpen: false }

    default:
      console.warn('demoReducer: unsupported action', action)
      return state
  }
}

const DemoContext = createContext<DemoState>(initialState)
const DemoDispatchContext = createContext<React.Dispatch<DemoAction> | null>(null)

export function DemoProvider({ children }: React.PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(demoReducer, initialState)

  return (
    <DemoContext.Provider value={state}>
      <DemoDispatchContext.Provider value={dispatch}>
        {children}
      </DemoDispatchContext.Provider>
    </DemoContext.Provider>
  )
}

export function useDemoState() {
  return useContext(DemoContext)
}

export function useDemoDispatch() {
  return useContext(DemoDispatchContext)
}

function wellIndexToPos(i: number, cols: number): string {
  const A = 'A'.charCodeAt(0)
  const rowIndex = Math.floor(i / cols)
  return `${String.fromCharCode(A + rowIndex)}${(i % cols) + 1}`
}

function initializeWells(rows: number, cols: number): Well[] {
  return new Array(rows * cols).fill(null).map((_, w) => ({
    index: w,
    pos: wellIndexToPos(w, cols),
    timepoints: new Array(14).fill(null).map((_, t) => ({
      actualTimestamp: START_TIME.add(t * 2, 'days')
        .add(w * 6, 'seconds')
        .toDate(),
      bucketTimestamp: START_TIME.add(t * 2, 'days').toDate(),
      kind: 'imaged',
      measurements: { confluence: t * 6 + Math.random() * 10 },
    })),
    sites: new Array(9).fill(null).map((_, s) => ({
      index: s,
      timepoints: new Array(14).fill(null).map((_, t) => ({
        actualTimestamp: START_TIME.add(t * 2, 'days')
          .add(s * 6, 'seconds')
          .toDate(),
        bucketTimestamp: START_TIME.add(t * 2, 'days').toDate(),
        kind: 'imaged',
        image: 'TODO',
      })),
    })),
  }))
}
