import { useFragment } from '@apollo/client'
import { Link } from 'react-router-dom'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'

import cs from './breadcrumbs.scss'
import RightArrowIcon from './icons/RightArrowIcon'

export default function Breadcrumbs({
  plate,
  wellPos,
}: { plate?: PlateProps; wellPos?: string }) {
  return (
    <nav className={cs.breadcrumbs}>
      <Link key='livecultures' to='/monitor/live-cultures'>
        Live Cultures
      </Link>

      {plate?.id != null ? (
        <>
          <PlateCrumb plate={plate} active={wellPos == null} />

          {wellPos != null ? (
            <WellCrumb plateID={plate.id} wellPos={wellPos} active />
          ) : null}
        </>
      ) : null}
    </nav>
  )
}

const plateCrumbFragment = gql(`
  fragment PlateCrumbFragment on CulturePlateGraphQL {
    id # Not used by PlateCrumb, but required for queries to seed the cache.
    barcode
  }
`)

interface PlateProps {
  /** Can be from URL if not yet fetched. */
  id: string
  /** Can be undefined if not yet fetched. */
  data?: FragmentType<typeof plateCrumbFragment>
}

/**
 * Render a link to the plate, falling back to the Apollo cache if the barcode
 * hasn't been fetched yet.
 */
function PlateCrumb({ plate, active }: { plate: PlateProps; active: boolean }) {
  const { id, data: queried } = plate
  const { data: cached } = useFragment({
    fragment: plateCrumbFragment,
    from: { __typename: 'CulturePlateGraphQL', id },
  })

  const barcode = unmaskFragment(plateCrumbFragment, queried)?.barcode ?? cached.barcode
  if (!barcode) {
    return null
  }

  return (
    <>
      <RightArrowIcon key='platesep' className={cs.separator} />
      <Link key='plate' to={`/plate/${plate.id}`} className={active ? cs.active : null}>
        {barcode}
      </Link>
    </>
  )
}

function WellCrumb({
  plateID,
  wellPos,
  active,
}: { plateID: string; wellPos: string; active: boolean }) {
  return (
    <>
      <RightArrowIcon key='wellsep' className={cs.separator} />
      <Link
        key='well'
        to={`/plate/${plateID}/well/${wellPos}`}
        className={active ? cs.active : null}
      >
        {wellPos}
      </Link>
    </>
  )
}
