import { Redirect, Route, Switch, useRouteMatch } from 'react-router'

import { DemoProvider } from './DemoContext'
import PlatePage from './PlatePage'
import WellRoutes from './well/WellRoutes'

export default function PlateRoutes() {
  const match = useRouteMatch()

  return (
    <DemoProvider>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to='/monitor/live-cultures' />}
        />

        <Route
          exact
          path={`${match.path}/:id`}
          render={({
            match: {
              params: { id },
            },
          }) => <PlatePage id={id} />}
        />

        <Route
          path={`${match.path}/:id/well`}
          render={({
            match: {
              params: { id },
            },
          }) => <WellRoutes plateID={id} />}
        />
      </Switch>
    </DemoProvider>
  )
}
