import { MontageLayoutGraphQl } from '~/__generated__/graphql'

/**
 * Maps a MontageLayout to its number of rows and columns.
 *
 * Note: This might not contain all MontageLayout cases! We think it's likely
 * that we'll support non-rectangular montage layouts eventually. When that time
 * comes, we'll need more UI logic than just a simple grid.
 */
export const MONTAGE_LAYOUT_ROWS_COLS = {
  [MontageLayoutGraphQl.Single]: [1, 1],
  [MontageLayoutGraphQl.Square_2X2]: [2, 2],
  [MontageLayoutGraphQl.Square_3X3]: [3, 3],
  [MontageLayoutGraphQl.Square_4X4]: [4, 4],
}

/**
 * Maps a plate format (expressed as number of wells) to a reasonable default
 * montage layout. This is only applicable when we're missing image data, such
 * as in loading states.
 */
export const PLACEHOLDER_MONTAGE_LAYOUT_FOR_PLATE_FORMAT = {
  96: MontageLayoutGraphQl.Single,
  24: MontageLayoutGraphQl.Square_2X2,
  12: MontageLayoutGraphQl.Square_3X3,
  6: MontageLayoutGraphQl.Square_4X4,
}
