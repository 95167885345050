import dayjs, { Dayjs } from 'dayjs'
import { useMemo } from 'react'

import LineGraph from '~/components/LineGraph'

import cs from './confluence_graph_sidebar.scss'

export interface ConfluenceDatasets {
  [datasetKey: string]: ConfluenceDataset
}

export interface ConfluenceDataset {
  name: string
  data: ConfluenceObservation[]
}

export interface ConfluenceObservation {
  timestamp: Dayjs
  confluence: number // Range: [0, 100]
}

export interface ConfluenceGraphSidebarProps {
  datasets: ConfluenceDatasets

  /**
   * The color of all lines. This component can't display multiple lines of
   * different colors at the same time (yet).
   */
  lineColor: string
}

export default function ConfluenceGraphSidebar(props: ConfluenceGraphSidebarProps) {
  /** An internal representation of an observation. */
  type InternalObservation = {
    // Note: We need to use the keyword `type` instead of `interface` to satisfy
    // D3LineGraphData.

    timestamp: number // Unix timestamp in seconds
    confluence: number // Range: [0, 100]
  }

  const datasets = useMemo(() => {
    const ret: {
      [datasetKey: string]: {
        name: string
        data: InternalObservation[]
      }
    } = {}
    for (const [datasetKey, dataset] of Object.entries(props.datasets)) {
      ret[datasetKey] = {
        name: dataset.name,
        data: dataset.data.map(({ timestamp, confluence }) => ({
          timestamp: timestamp.unix(),
          confluence,
        })),
      }
    }
    return ret
  }, [props.datasets])

  const graphData = Object.fromEntries(
    Object.entries(datasets).map(([datasetKey, dataset]) => [datasetKey, dataset.data]),
  )

  const allTimestamps = Object.values(datasets)
    .flatMap(dataset => dataset.data)
    .map(obs => obs.timestamp)
    .sort()
  const minTime = allTimestamps[0] ?? Date.now() / 1000
  const maxTime = allTimestamps[allTimestamps.length - 1] ?? Date.now() / 1000

  // An empty region on either side of the X domain keeps the line from
  // overlapping the Y axis labels.
  const xAxisBuffer = ((maxTime - minTime) * 0.19) / 2
  const xDomain: [number, number] = [minTime - xAxisBuffer, maxTime + xAxisBuffer]
  const isLessThanOneDay = dayjs(maxTime).diff(dayjs(minTime)) < 24 * 60 * 60 * 1000

  return (
    <aside className={cs.container}>
      <h2 className={cs.title}>Confluence Over Time</h2>

      <LineGraph
        className={cs.graph}
        layoutOptions={{
          marginTop: 0,
          marginRight: 0,
          marginLeft: 0,
        }}
        axisOptions={{
          xAxisBorderClassName: cs.xAxisBorder,
          xTickValues:
            allTimestamps.length === 1
              ? [minTime]
              : [minTime, minTime + (maxTime - minTime / 2), maxTime],
          xTickFormat: isLessThanOneDay
            ? (unix: number) => dayjs(unix).format('h:mm A')
            : (unix: number) => dayjs(unix).format('MMM D'),
          xTickSize: 0,
          xTickLabelClassName: cs.xTickLabels,

          yAxisBorderClassName: cs.yAxisBorder,
          yTickValues: [20, 40, 60, 80, 100],
          yTickFormat: (c: number) => (c > 0 ? `${c}%` : ''),
          yTickSize: -330, // Tech Debt: To make this responsive, we need to query the container width or try styling the <line> elements instead.
          yTickLineClassName: cs.yTickLines,
          yTickLabelClassName: cs.yTickLabels,
        }}
        options={{
          xDomain,
          xKey: 'timestamp',

          yDomain: [0, 100],
          yKey: 'confluence',

          lineColor: props.lineColor,
        }}
        data={graphData}
      />
    </aside>
  )
}
