import { useFragment, useQuery } from '@apollo/client'
import cx from 'classnames'
import { useEffect } from 'react'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'
import { PlatePageQuery, WellCultureStatusGraphQl } from '~/__generated__/graphql'
import Breadcrumbs from '~/components/Breadcrumbs'
import { FullPageError } from '~/components/Errors'
import PageNotFound from '~/components/PageNotFound'
import PlateHero, { PlateHeroLoadingPlaceholder } from '~/components/PlateHero'
import ZeroWidthSpaceToken from '~/components/ZeroWidthSpaceToken'
import { analytics } from '~/core/analytics'

import PlateConfluenceGraphSidebar from './PlateConfluenceGraphSidebar'
import PlateMetadataSidebar from './PlateMetadataSidebar'
import cs from './plate.scss'

const GRAPHQL_QUERY = gql(`
  query PlatePage($id: UUID!) {
    culturePlate(id: $id) {
      id
      barcode
      plateObservationHistory {
        timestamp
      }
      ...PlateCrumbFragment
      ...PlateStatusFragment
      ...PlateHeroFragment
      ...PlateConfluenceGraphSidebarFragment
      ...PlateMetadataSidebarFragment
    }
  }
`)

export default function PlatePage({ id }: { id: string }) {
  const { loading, error, data } = useQuery(GRAPHQL_QUERY, {
    variables: { id },
  })
  if (loading) {
    return <LoadingPlaceholder id={id} />
  }
  if (error) {
    return <FullPageError error={error} />
  }
  const plate = data?.culturePlate
  if (plate == null) {
    return <PageNotFound />
  }
  return <Content plate={plate} />
}

function Content({ plate }: { plate: NonNullable<PlatePageQuery['culturePlate']> }) {
  const [latestPlateObservationTime] = plate.plateObservationHistory
    .map(obs => obs.timestamp)
    .sort()
    .slice(-1) as [string | undefined]

  useEffect(() => {
    analytics.page('Monitor', 'Plate', {
      plateID: plate.id,
      numPlateObservations: plate.plateObservationHistory.length,
      latestPlateObservationTime: latestPlateObservationTime ?? null,
    })
  }, [])

  return (
    <div>
      <Breadcrumbs plate={{ id: plate.id, data: plate }} />

      <div className={cs.main}>
        <div className={cs.title}>
          {plate.barcode}
          <ZeroWidthSpaceToken />
          <PlateStatus plate={plate} />
        </div>

        <div className={cs.heroLabel}>
          {plate.plateObservationHistory.length > 0 ? (
            <>Latest Images</>
          ) : (
            <>No images yet.</>
          )}
        </div>

        <div>
          <PlateHero plate={plate} />
        </div>

        <div>
          <PlateConfluenceGraphSidebar plate={plate} />
          <PlateMetadataSidebar plate={plate} />
        </div>
      </div>
    </div>
  )
}

const platePageLoadingPlaceholderFragment = gql(`
  fragment PlatePageLoadingPlaceholderFragment on CulturePlateGraphQL {
    barcode
    ...PlateHeroLoadingPlaceholderFragment
  }
`)

function LoadingPlaceholder({ id }: { id: string }) {
  const { data: cached, complete } = useFragment({
    fragment: platePageLoadingPlaceholderFragment,
    fragmentName: 'PlatePageLoadingPlaceholderFragment',
    from: { __typename: 'CulturePlateGraphQL', id },
  })

  return (
    <div>
      <Breadcrumbs plate={{ id }} />

      <div className={cs.main}>
        <div className={cs.title}>
          {cached.barcode}
          <ZeroWidthSpaceToken />
        </div>

        <div className={cs.heroLabel}>&nbsp;</div>

        <div>{complete ? <PlateHeroLoadingPlaceholder cached={cached} /> : null}</div>
      </div>
    </div>
  )
}

const plateStatusFragment = gql(`
  fragment PlateStatusFragment on CulturePlateGraphQL {
    wellCultures {
      status
    }
  }
`)

function PlateStatus(props: { plate: FragmentType<typeof plateStatusFragment> }) {
  const plate = unmaskFragment(plateStatusFragment, props.plate)
  const statuses = new Set(plate.wellCultures.map(c => c.status))

  // All wells are checked out
  if (statuses.has(WellCultureStatusGraphQl.CheckedOut)) {
    if (statuses.size > 1) {
      console.warn('PlateStatus: Unsupported partially checked out plate')
      return null
    }
    return <span className={cs.status}>Checked Out</span>
  }

  // Some wells are active
  if (statuses.has(WellCultureStatusGraphQl.Active)) {
    return <span className={cx(cs.status, cs.checkedIn)}>Checked In</span>
  }

  // All wells are either consumed or terminated
  return <span className={cs.status}>Inactive</span>
}
