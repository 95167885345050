import cx from 'classnames'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import PageNotFound from '~/components/PageNotFound'
import Button from '~/components/buttons/Button'
import CloseIcon from '~/components/icons/CloseIcon'
import ExecuteIcon from '~/components/icons/ExecuteIcon'
import { getConfluencyColor } from '~/pages/Monitor/pages/LiveCultures/LiveCultures'

import { Site, Timepoint, useDemoDispatch, useDemoState } from '../../DemoContext'
import cs from './site.scss'

export default function SiteView({
  plateID,
  wellPos,
  index,
}: { plateID: string; wellPos: string; index: string }) {
  const { plates, decisionsOpen } = useDemoState()
  const dispatch = useDemoDispatch()

  const plate = plates.find(p => p.id === plateID)
  if (plate == null) {
    return <PageNotFound />
  }
  const well = plate.wells.find(w => w.pos === wellPos)
  if (well == null) {
    return <PageNotFound />
  }
  const site: Site = well.sites[index]
  if (site == null) {
    return <PageNotFound />
  }

  const reversedTimepoints = useMemo(() => {
    return [...site.timepoints].reverse()
  }, [site.timepoints])

  // const collatedTimepoints = useMemo(() => {
  //   const timepointsByBucketTimestamp: { [bucket: string]: Timepoint } =
  //     Object.fromEntries(
  //       well.timepoints.map(timepoint => [
  //         timepoint.bucketTimestamp.toISOString(),
  //         { ...timepoint, sites: [] },
  //       ]),
  //     )
  //   for (const site of well.sites) {
  //     for (const siteTimepoint of site.timepoints) {
  //       const bucket = siteTimepoint.bucketTimestamp.toISOString()
  //       timepointsByBucketTimestamp[bucket].sites.push({
  //         ...site,
  //         timepoints: [siteTimepoint],
  //       })
  //     }
  //   }
  //   return Object.values(timepointsByBucketTimestamp).sort((a, b) =>
  //     a.bucketTimestamp < b.bucketTimestamp ? 1 : -1,
  //   )
  // }, [well.timepoints, well.sites])

  return (
    <div>
      <div className={cs.main}>
        <section>
          <nav className={cs.timeline}>
            {reversedTimepoints.map(timepoint => (
              <TimepointTab
                key={timepoint.bucketTimestamp.getTime()}
                timepoint={timepoint}
                site={site}
              />
            ))}
          </nav>
        </section>

        <section className={cs.detail}>
          <h1>
            {/* TODO(SWE-1337): Change site index to number? */}
            {plate.name} ({plate.id}) • Well {well.pos} • Site {parseInt(index) + 1}
          </h1>
          <BigImage site={site} />
        </section>

        {decisionsOpen ? (
          <section>
            <DecisionsSidebar />
          </section>
        ) : (
          <div>
            <Button
              variant='minimal'
              IconComponent={ExecuteIcon}
              onClick={() => {
                dispatch?.('OPEN_DECISIONS')
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

function TimepointTab({ timepoint, site }: { timepoint: Timepoint; site: Site }) {
  const confluence = timepoint.measurements?.confluence

  return (
    <Link to='#TODO'>
      <div
        className={cs.timepoint}
        style={{
          // @ts-expect-error Type definition doesn't support CSS variables.
          '--accent-color': confluence ? getConfluencyColor(confluence, true) : null,
        }}
      >
        <div className={cs.time}>
          {dayjs(timepoint.actualTimestamp).format('MMM D, YYYY H:mm A')}
        </div>

        <div className={cs.readouts}>
          <div>
            <MiniSite site={site} />
          </div>
          {confluence ? (
            <span
              className={cs.confluence}
              style={{ color: getConfluencyColor(confluence, true) }}
            >
              {Math.round(confluence)}%
            </span>
          ) : null}
        </div>
      </div>
    </Link>
  )
}

function MiniSite({ site }: { site: Site }) {
  return (
    <div className={cs.minisite}>
      <div className={cs.thumbnail}>
        <img
          src='https://monomer-slas-demo-cell-images.s3.us-west-2.amazonaws.com/organoid_demo_2024_v2/300px/231221_091518_CD318_TnnT__20231221_091517/A2_03_1_1_Bright%20Field_001.jpg'
          style={{
            transform: `translate(${-100}px, ${-100}px)`,
          }}
        />
      </div>
    </div>
  )
}

function BigImage({ site }: { site: Site }) {
  return (
    <div className={cx(cs.bigimage, cs.hasImage)}>
      <img
        src='https://monomer-slas-demo-cell-images.s3.us-west-2.amazonaws.com/organoid_demo_2024_v2/1000px/231221_091518_CD318_TnnT__20231221_091517/A1_03_1_1_Bright%20Field_001.jpg'
        style={{
          transform: `translate(${-200}px, ${-200}px)`,
        }}
      />
    </div>
  )
}

function DecisionsSidebar() {
  const dispatch = useDemoDispatch()

  return (
    <div className={cs.decisions}>
      <div className={cs.header}>
        <h2>Decision</h2>

        <Button
          IconComponent={CloseIcon}
          variant='minimal'
          onClick={() => {
            dispatch?.('CLOSE_DECISIONS')
          }}
        />
      </div>

      <fieldset>
        <legend>Mark this well:</legend>

        <div>
          <input type='radio' id='markPassage' name='decision' value='passage' />
          <label htmlFor='markPassage'>Ready to passage</label>
        </div>

        <div>
          <input type='radio' id='markEmpty' name='decision' value='empty' />
          <label htmlFor='markEmpty'>Empty</label>
        </div>

        <div>
          <input
            type='radio'
            id='markMulticlonal'
            name='decision'
            value='multiclonal'
          />
          <label htmlFor='markMulticlonal'>Multiclonal</label>
        </div>
      </fieldset>

      <div className={cs.buttons}>
        <Button onClick={() => {}} label='Save' type='primary' className={cs.button} />
        <Button
          onClick={() => {}}
          label='Save and go to next'
          type='primary'
          className={cs.button}
        />
      </div>
    </div>
  )
}
