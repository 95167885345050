import cx from 'classnames'
import { useEffect, useState } from 'react'

import Textarea from '~/components/Textarea'
import Toaster from '~/components/Toaster'

import cs from './json_edit_dialog_tab.scss'

import { DefaultSuccessResponse } from '~/api/desktop'
import {
  DefaultGetJsonResponse,
  DefaultUpdateJsonRequest,
} from '~/api/desktop/workcellRecovery.interface'
import Dialog from './Dialog'

interface JsonEditDialogTabApi {
  setJson: (newJson: DefaultUpdateJsonRequest) => Promise<DefaultSuccessResponse>
  getJson: () => Promise<DefaultGetJsonResponse>
}

interface JsonEditDialogTabProps {
  className?: string
  onClose: () => void
  heightSizing?: 'flex' | 'default' | undefined
  api: JsonEditDialogTabApi
}

const JsonEditDialogTab = ({
  className,
  api,
  onClose,
  heightSizing = 'default',
}: JsonEditDialogTabProps) => {
  // ui or json
  const [json, setJson] = useState<Record<string, unknown> | null>(null)
  const [jsonEdits, setJsonEdits] = useState<string>('')
  const [error, setError] = useState('')

  const hydrateJsonEdits = _json => {
    setJsonEdits(JSON.stringify(_json, undefined, 2))
  }

  const initialize = async () => {
    const _json = await api.getJson()
    console.log(_json)

    setJson(_json.json_values)
    hydrateJsonEdits(_json.json_values)
  }
  useEffect(() => {
    initialize()
  }, [])

  const handleJsonSave = async () => {
    const oldJson = json
    let newJson: Record<string, never>

    try {
      newJson = JSON.parse(jsonEdits)
      setError('')
    } catch (e) {
      setError('Invalid JSON')
      return
    }

    setJson(newJson)
    if (onClose) onClose()
    try {
      await api.setJson({
        json_values: newJson,
      })
    } catch (_error) {
      // No need to handle disconnect error here, since it is handled with regular updateStatus.
      Toaster.show({
        message: 'New options failed to saved.',
        intent: 'danger',
      })
      setJson(oldJson)
      return
    }
    Toaster.show({ message: 'New options saved.', intent: 'success' })
  }

  if (!json) return null

  return (
    <div className={cx(cs.jsonEditDialogTab, className, cs[heightSizing])}>
      <Textarea className={cs.textarea} value={jsonEdits} onChange={setJsonEdits} />
      {error && <div className={cs.error}>{error}</div>}
      <Dialog.Footer>
        <Dialog.FooterButton label='Cancel' onClick={onClose} />
        <Dialog.FooterButton
          type='primary'
          label='Save Changes'
          onClick={handleJsonSave}
        />
        <div className={cs.fill} />
      </Dialog.Footer>
    </div>
  )
}

export default JsonEditDialogTab
