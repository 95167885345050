import cx from 'classnames'
import { entries, flatten, flow, get, map, orderBy } from 'lodash/fp'

import Table from '~/components/Table'
import Notification from '~/components/notifications/Notification'
import PropTypes from '~/propTypes'

import ProcessItem from './ProcessItem'
import cs from './transfer_stations.scss'

const TransferStations = ({ className, instruments, instrumentStatus, live }) => {
  if (!instruments) return null

  const transferStations = flow(
    entries,
    map(([instrumentName, status]) =>
      map(
        ([transferStationName, processItem]) => ({
          transferStationName,
          processItem,
          instrumentName,
          rowKey: `${instrumentName}_${transferStationName}`,
        }),
        entries(status.transfer_stations),
      ),
    ),
    flatten,
    orderBy([get('instrumentName'), get('transferStationName')], ['asc', 'asc']),
  )(instrumentStatus)

  const tableColumns = [
    {
      name: 'Instrument',
      width: 200,
      render: row => row.instrumentName,
      smallText: true,
    },
    {
      name: 'Transfer Station',
      width: 250,
      render: row => <b>{row.transferStationName}</b>,
    },
    {
      name: 'Process Item',
      width: 300,
      render: row => (
        <ProcessItem className={cs.processItem} processItem={row} live={live} />
      ),
    },
  ]

  return (
    <div className={cx(className, cs.transferStations)}>
      {live && (
        <Notification
          type='accent'
          label='Workcell is live. Cannot load or unload transfer stations.'
          className={cs.liveNotification}
        />
      )}
      <Table
        columns={tableColumns}
        data={transferStations}
        className={cs.table}
        heightSizing='default'
        rowPaddingVariant='rowPaddingLow'
        rowKey='rowKey'
      />
    </div>
  )
}

TransferStations.propTypes = {
  className: PropTypes.string,
  instruments: PropTypes.arrayOf(
    PropTypes.shape({
      instrumentName: PropTypes.string,
      instrumentType: PropTypes.string,
    }),
  ),
  instrumentStatus: PropTypes.InstrumentStatus,
  live: PropTypes.bool,
}

export default TransferStations
