import { useFragment, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { gql } from '~/__generated__'
import { WellPageQuery } from '~/__generated__/graphql'
import Breadcrumbs from '~/components/Breadcrumbs'
import { FullPageError } from '~/components/Errors'
import MontageHero, { MontageHeroLoadingPlaceholder } from '~/components/MontageHero'
import PageNotFound from '~/components/PageNotFound'
import TinyMicroplate from '~/components/TinyMicroplate'
import {
  SUPPORTED_PLATE_DIMENSIONS,
  supportedPlateFormats,
} from '~/components/TinyMicroplate.interface'
import ZeroWidthSpaceToken from '~/components/ZeroWidthSpaceToken'
import { analytics } from '~/core/analytics'
import { CultureStatusText } from '~/pages/Monitor/pages/CulturePage/CultureStatus'
import MontageImagesDialog from '~/pages/Monitor/pages/MontageImagesDialog/MontageImagesDialog'
import {
  convertWellCoordsToWellName,
  convertWellNameToWellCoord,
} from '~/utils/microplate'
import { PLACEHOLDER_MONTAGE_LAYOUT_FOR_PLATE_FORMAT } from '~/utils/montage'

import LateralNavCompass, { LateralNavDirection } from './LateralNavCompass'
import WellConfluenceGraphSidebar from './WellConfluenceGraphSidebar'
import WellMetadataSidebar from './WellMetadataSidebar'
import cs from './well_page.scss'

const query = gql(`
  query WellPage($plateId: UUID!, $position: String!) {
    culture: wellCultureByPlateIdAndPosition(
      plateId: $plateId
      position: $position
    ) {
      id
      name
      well
      status
      observationHistory {
        timestamp
      }
      culturePlate {
        id
        wellCultures {
          well
        }
        plateDimensions {
          rows
          columns
        }
        ...PlateCrumbFragment
      }
      montage {
        ...MontageImagesDialogFragment
      }
      ...MontageHeroFragment
      ...WellMetadataSidebarFragment
      ...WellConfluenceGraphSidebarFragment
    }
  }
`)

export default function WellPage({ plateID, pos }: { plateID: string; pos: string }) {
  const { loading, error, data } = useQuery(query, {
    variables: { plateId: plateID, position: pos },
  })
  if (loading) {
    return <LoadingPlaceholder plateID={plateID} pos={pos} />
  }
  if (error) {
    return <FullPageError error={error} />
  }
  const culture = data?.culture
  if (culture == null) {
    return <PageNotFound />
  }
  return <Content culture={culture} />
}

function Content({ culture }: { culture: NonNullable<WellPageQuery['culture']> }) {
  const history = useHistory()

  // The index of the image the user clicked, or null if the image dialog is closed.
  // Note: This doesn't update when switching images within the dialog.
  const [openedImage, setOpenedImage] = useState<number | null>(null)

  const latestTimestamp =
    culture.observationHistory.length > 0
      ? dayjs(
          culture.observationHistory[culture.observationHistory.length - 1].timestamp,
        )
      : null
  const latestTimestampThisYear = latestTimestamp?.year() === dayjs().year()

  useEffect(() => {
    analytics.page('Monitor', 'Well', {
      cultureID: culture.id,
      numCultureObservations: culture.observationHistory.length,
      latestCultureObservationTime: latestTimestamp?.toDate() ?? null,
    })
  }, [])

  const plateDims = culture.culturePlate.plateDimensions
  const tinyMicroplateFormat = SUPPORTED_PLATE_DIMENSIONS.some(
    ({ rows, columns }) => rows === plateDims.rows && columns === plateDims.columns,
  )
    ? (`wells_${plateDims.rows * plateDims.columns}` as supportedPlateFormats)
    : null

  return (
    <div>
      <Breadcrumbs plate={culture.culturePlate} wellPos={culture.well} />

      <div className={cs.main}>
        <div className={cs.titleArea}>
          <div>
            <span className={cs.title}>Well {culture.well}</span>
            <ZeroWidthSpaceToken />
            <CultureStatusText status={culture.status} className={cs.status} />
          </div>

          <LateralNavControls
            currentWell={culture.well}
            availableWells={culture.culturePlate.wellCultures.map(c => c.well)}
            tinyMicroplateFormat={tinyMicroplateFormat}
            onNavigate={toWell => {
              if (toWell !== culture.well) {
                history.push(`/plate/${culture.culturePlate.id}/well/${toWell}`)
              }
            }}
          />
        </div>

        <div className={cs.heroLabel}>
          {latestTimestamp ? (
            <>
              Latest Images •{' '}
              {latestTimestamp.format(
                latestTimestampThisYear ? 'MMM D' : 'MMM D, YYYY',
              )}
              &ensp;
              {latestTimestamp.format('h:mm A')}
            </>
          ) : (
            <>No images yet.</>
          )}
        </div>

        <div className={cs.hero}>
          <MontageHero
            culture={culture}
            onClickImage={
              culture.montage
                ? idx => {
                    setOpenedImage(idx)
                  }
                : undefined
            }
          />
        </div>

        <div className={cs.sidebar}>
          <WellConfluenceGraphSidebar culture={culture} />
          <WellMetadataSidebar culture={culture} />
        </div>
      </div>

      {culture.montage && openedImage != null ? (
        // Normally we'd keep this mounted and use the `isOpen` prop to control
        // its visibility. Here, as a hack, we unmount it when closed, so that
        // it always resets to the `initialImageIndex` when opened.
        <MontageImagesDialog
          montage={culture.montage}
          isOpen
          onClose={() => {
            setOpenedImage(null)
          }}
          initialImageIndex={openedImage ?? undefined}
          linkToCulture={false}
        />
      ) : null}
    </div>
  )
}

const wellPageLoadingPlaceholderPlateLookupFragment = gql(`
  fragment WellPageLoadingPlaceholder_PlateLookupFragment on CulturePlateGraphQL {
    plateDimensions {
      rows
      columns
    }
    wellCultures {
      well
    }
  }
`)

function LoadingPlaceholder({ plateID, pos }: { plateID: string; pos: string }) {
  const history = useHistory()
  const { data: cachedPlate, complete } = useFragment({
    fragment: wellPageLoadingPlaceholderPlateLookupFragment,
    from: { __typename: 'CulturePlateGraphQL', id: plateID },
  })

  const tinyMicroplateFormat =
    complete &&
    SUPPORTED_PLATE_DIMENSIONS.some(
      ({ rows, columns }) =>
        rows === cachedPlate.plateDimensions.rows &&
        columns === cachedPlate.plateDimensions.columns,
    )
      ? (`wells_${cachedPlate.plateDimensions.rows * cachedPlate.plateDimensions.columns}` as supportedPlateFormats)
      : null

  return (
    <div>
      <Breadcrumbs plate={{ id: plateID }} wellPos={pos} />

      <div className={cs.main}>
        <div className={cs.titleArea}>
          <div>
            <span className={cs.title}>Well {pos}</span>
          </div>

          <LateralNavControls
            currentWell={pos}
            availableWells={cachedPlate.wellCultures?.map(c => c.well) ?? [pos]}
            tinyMicroplateFormat={tinyMicroplateFormat}
            onNavigate={toWell => {
              if (toWell !== pos) {
                history.push(`/plate/${plateID}/well/${toWell}`)
              }
            }}
          />
        </div>

        <div className={cs.heroLabel}>&nbsp;</div>

        <div className={cs.hero}>
          <MontageHeroLoadingPlaceholder
            montageLayout={
              complete
                ? PLACEHOLDER_MONTAGE_LAYOUT_FOR_PLATE_FORMAT[
                    cachedPlate.plateDimensions.rows *
                      cachedPlate.plateDimensions.columns
                  ]
                : undefined
            }
          />
        </div>
      </div>
    </div>
  )
}

function LateralNavControls({
  currentWell,
  availableWells,
  tinyMicroplateFormat,
  onNavigate,
}: {
  currentWell: string
  availableWells: string[]
  tinyMicroplateFormat: supportedPlateFormats | null
  onNavigate: (toWell: string) => void
}) {
  const availableWellsSet = useMemo(() => new Set(availableWells), [availableWells])
  const nextWell = useWellNavOrdering(currentWell, availableWells)

  return (
    <div className={cs.lateralNavControls}>
      {tinyMicroplateFormat ? (
        <TinyMicroplate
          className={cs.tinyMicroplate}
          plateFormat={tinyMicroplateFormat}
          highlights={[
            {
              colorRgbFn: (row: number, col: number) => {
                const well = convertWellCoordsToWellName(row, col)
                if (well === currentWell) {
                  return '#2cb1bc' // Accent
                }
                if (availableWellsSet.has(well)) {
                  return '#ccc' // Grey Light
                }
                return '#eaeaea' // Grey Lightest
              },
            },
          ]}
        />
      ) : null}

      <div className={cs.compassContainer}>
        <LateralNavCompass
          onNavigate={direction => {
            onNavigate(nextWell(direction))
          }}
          upLabel={nextWell(LateralNavDirection.UP)}
          downLabel={nextWell(LateralNavDirection.DOWN)}
          leftLabel={nextWell(LateralNavDirection.LEFT)}
          rightLabel={nextWell(LateralNavDirection.RIGHT)}
        />
      </div>
    </div>
  )
}

/**
 * Return a function that returns the next well in a particular 2D direction.
 *
 * If there are no available wells, the returned function always returns the
 * current well.
 */
function useWellNavOrdering(
  currentWell: string,
  availableWells: string[],
): (dir: LateralNavDirection) => string {
  const orderings = useMemo(() => {
    const availableCoords = availableWells.map(well => convertWellNameToWellCoord(well))

    // Horizontal navigation uses row-major order: [A1, A2, A3, B1, B2, B3]
    const horizontal = availableCoords
      .toSorted((a, b) => a.colIndex - b.colIndex)
      .toSorted((a, b) => a.rowIndex - b.rowIndex)
      .map(({ rowIndex, colIndex }) => convertWellCoordsToWellName(rowIndex, colIndex))

    // Vertical navigation uses column-major order: [A1, B1, A2, B2, A3, B3]
    const vertical = availableCoords
      .toSorted((a, b) => a.rowIndex - b.rowIndex)
      .toSorted((a, b) => a.colIndex - b.colIndex)
      .map(({ rowIndex, colIndex }) => convertWellCoordsToWellName(rowIndex, colIndex))

    return { horizontal, vertical }
  }, [availableWells])

  const movement: { [key in LateralNavDirection]: [string[], number] } = {
    [LateralNavDirection.UP]: [orderings.vertical, -1],
    [LateralNavDirection.DOWN]: [orderings.vertical, +1],
    [LateralNavDirection.LEFT]: [orderings.horizontal, -1],
    [LateralNavDirection.RIGHT]: [orderings.horizontal, +1],
  }

  return (direction: LateralNavDirection) => {
    const [order, step] = movement[direction]
    if (order.length === 0) {
      return currentWell
    }

    const currentIndex = order.indexOf(currentWell)
    let newIndex = (currentIndex + step) % order.length
    if (newIndex === -1) {
      newIndex = order.length - 1
    }
    return order[newIndex]
  }
}
